import React, { useRef } from 'react'
import gsap from 'gsap'

const AudioControls = ({ isPlaying, onPlayPauseClick }) => {
  const button = useRef(null)

  return (
    <div className='audio-controls'>
    <button ref={button} type='button' onClick={() => {
      gsap.timeline({})
      .to(button.current, {
         scale : .95,
         color : '#e7e5df',
         duration : .25,
         ease : 'back.out'
      })
      .call(() => {onPlayPauseClick(!isPlaying)})
      .to(button.current, {
         scale : 1,
         color : '#393e41',
         duration : .25,
         ease : 'back.out'
      })
      }}>
      { isPlaying ? 'Ferma' : 'Riproduci' }
    </button>
    </div>
  )
}

export default AudioControls
