// @ts-nocheck
/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

// functions
import LocomotiveScroll from 'locomotive-scroll'
import { isMobile } from 'react-device-detect'

// components
import { StaticImage } from 'gatsby-plugin-image'
import ReactPlayer from 'react-player/file'

import Seo from '../utils/seo'
import SplitText from '../functional-components/split-text'
import ArrrowLink from '../micro-components/arrow-link'
import AudioPlayer from '../micro-components/react-audio-player/AudioPlayer'
import TransButton from '../components/trans-button'

// video Srcs
import video from '../video/video.mp4'
import videoMobile from '../video/mobile/videoMobile.mp4'

// audio Srcs
import track1Src from '../audio/ercole.mp3'
import track2Src from '../audio/debora.mp3'

// style
import '../style/index.scss'

gsap.registerPlugin(ScrollTrigger)

const Index = () => {
  const scroll = useRef(null)
  const index = useRef(null)
  const mainTl = useRef(null)

  // video const
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const videoSrc =
    isMobile && document.documentElement.clientWidth < 575 ? videoMobile : video

  const track1 = [
    {
      title: 'Ercole in Tebe',
      artist:
        "Estratto della registrazione dell'opera Ercole in Tebe al Teatro della Pergola.",
      audioSrc: track1Src,
    },
  ]

  const track2 = [
    {
      title: 'Debora Tempestini',
      artist: "C.Debussy (1862-1918): L'isle joyeuse L. 106",
      audioSrc: track2Src,
    },
  ]

  useEffect(() => {
    function callback(mutationList) {
      mutationList.forEach((mutation) => {
        switch (mutation.type) {
          case 'attributes':
            switch (mutation.attributeName) {
              case 'menu-status':
                const state = document
                  .querySelector('[menu-status]')
                  .getAttribute('menu-status')
                if (state === 'close') {
                  scroll.current.start()
                }
                if (state === 'open') {
                  scroll.current.stop()
                }
                break
              case 'main-visible-status':
                const visible = document
                  .querySelector('[main-visible-status]')
                  .getAttribute('main-visible-status')
                if (visible === 'visible') mainTl.current.play()

                break
              default:
                break
            }
            break
          default:
            break
        }
      })
    }
    // init Locomotive Sroll and ScrollTrigger
    scroll.current = new LocomotiveScroll({
      el: document.querySelector('.scroll-section'),
      smooth: true,
      multiplier: 0.75,
      firefoxMultiplier: 45,
      lerp: 0.1,
      smartphone: {
        breakpoint: 0,
        smooth: false,
      },
      tablet: {
        breakpoint: 0,
        smooth: false,
      },
    })
    // update ScrollTrigger each time LocomotiveScroll is updated
    scroll.current.on('scroll', ScrollTrigger.update)
    // Set ScrollTrigger to 'lister' to LocomotiveScroll instead of natural scroll
    ScrollTrigger.scrollerProxy('.scroll-section', {
      // this is need to define vertical scroll
      scrollTop(value) {
        return arguments.length
          ? scroll.current.scrollTo(value, 0, 0)
          : scroll.current.scroll.instance.scroll.y
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      },
      // pin fixed elements to control mobile version of LocomotiveScroll
      pinType: document.querySelector('.scroll-section').style.transform
        ? 'transform'
        : 'fixed',
    })

    // Obsever section
    const targetNode1 = document.querySelector('[menu-status]'),
      targetNode2 = document.querySelector('[main-visible-status]')
    // observe menu for changes
    const observer1 = new MutationObserver(callback),
      observer2 = new MutationObserver(callback)
    // Start observing the target node for configured mutations
    observer1.observe(targetNode1, {
      attributes: true,
      attributeFilter: ['menu-status'],
      childList: false,
      subtree: false,
    })
    observer2.observe(targetNode2, {
      attributes: true,
      attributeFilter: ['main-visible-status'],
      childList: false,
      subtree: false,
    })

    // // PUT THERE ALL THE TIMELINES // //
    const q = gsap.utils.selector(index)

    // define main timeline
    mainTl.current = gsap.timeline({
      paused: true,
      defaults: {
        ease: 'expo.out',
      },
    })
    mainTl.current
      .fromTo(
        q('.index-title span:not(.index-subtitle) span'),
        {
          yPercent: 100,
        },
        {
          yPercent: 0,
          stagger: {
            amount: 0.5,
          },
          duration: 1.5,
        }
      )
      .fromTo(
        q('.index-subtitle > *'),
        {
          yPercent: 125,
        },
        {
          yPercent: 0,
          stagger: {
            amount: 0.25,
          },
          duration: 1.25,
        },
        0.25
      )
      .fromTo(
        q('.index-intro-separator'),
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: 1.25,
        },
        0.5
      )
      .fromTo(
        q('.index-desc span'),
        {
          yPercent: 125,
        },
        {
          yPercent: 0,
          stagger: {
            amount: 0.25,
          },
          duration: 1.25,
        },
        0.5
      )
      .fromTo(
        q('.img-intro-container'),
        {
          autoAlpha: 0,
          yPercent: 50,
        },
        {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1.25,
        },
        0.25
      )

    // follow mouse photo
    if (!isMobile) {
      // gsap.set(q('.img-intro-container'), { display: 'block' })
      document
        .querySelector('.index-intro-container')
        .addEventListener('mousemove', (e) => {
          const xPos = e.clientX,
            yPos = e.clientY
          gsap.to(q('.img-intro-container'), {
            y: yPos / -25,
            x: xPos / -25,
            ease: 'none',
          })
        })
    }

    // video section

    ScrollTrigger.matchMedia({
      '(min-width : 1001px)' : function () {
        const scrollVideoTl = gsap.timeline({
          scrollTrigger: {
            trigger: '.scroll-container',
            scroller: '.scroll-section',
            start: 'top top',
            end: '+=2000',
            pin: true,
            pinSpacing: 'margin',
            scrub: 1,
            onEnter: () => setIsPlaying(true),
          },
        })
        scrollVideoTl
          .fromTo(
            q('.video-container'),
            {
              top: '50%',
              left: '50%',
              yPercent: -50,
              xPercent: -50,
            },
            {
              scale: 0.6,
              duration: 10,
              ease: 'none',
            }
          )
          .fromTo(
            q('.scroll-container .film-title span span'),
            {
              yPercent: 200,
            },
            {
              yPercent: 0,
              duration: 4,
              stagger: {
                amount: 4,
              },
              ease: 'expo.out',
            },
            4
          )
          .fromTo(
            q('.scroll-container .film-title'),
            {
              top: '50%',
              left: '50%',
              yPercent: -50,
              xPercent: -50,
            },
            {
              yPercent: -100,
              top: '100%',
              duration: 2,
              ease: 'sin.out',
            }
          )

        // scroll list
        const tl = gsap.timeline().fromTo(
          q('.riprese-video-container .bg-img-subject'),
          {
            yPercent: 15,
          },
          {
            scrollTrigger: {
              trigger: '.riprese-video-container',
              scroller: '.scroll-section',
              start: 'top 75%',
              end: 'bottom top',
              scrub: true,
            },
            yPercent: 0,
          }
        )

        const tl1 = gsap.timeline().fromTo(
          q('.riprese-aeree-container .bg-img-bottom'),
          {
            yPercent: 0,
          },
          {
            scrollTrigger: {
              trigger: '.riprese-aeree-container',
              scroller: '.scroll-section',
              start: 'top 75%',
              end: 'bottom top',
              scrub: true,
            },
            yPercent: 4,
          }
        )

        const tl2 = gsap.timeline().fromTo(
          q('.editing-container .bg-img-subject.cursor'),
          {
            xPercent: 0,
          },
          {
            scrollTrigger: {
              trigger: '.editing-container',
              scroller: '.scroll-section',
              start: 'top 75%',
              end: 'bottom top',
              scrub: true,
            },
            xPercent: 100,
            ease: 'none',
          }
        )
        gsap.utils
          .toArray('.scroll-list li:not(.line-container)')
          .forEach((li) => {
            const liTl = gsap.timeline({
              scrollTrigger: {
                trigger: li,
                scroller: '.scroll-section',
                start: 'top center',
                end: 'bottom center',
                toggleActions: 'play none none reverse',
                onEnter: () => liTl.timeScale(1.0),
                onEnterBack: () => liTl.timeScale(3.0),
                fastScrollEnd: true,
              },
            })
            liTl
              .fromTo(
                li.querySelector('.dot'),
                {
                  scale: 0,
                },
                {
                  scale: 1,
                  ease: 'back.out',
                },
                0
              )
              .fromTo(
                li.querySelector('span'),
                {
                  yPercent: 150,
                },
                {
                  yPercent: 0,
                  duration: 1.25,
                  ease: 'expo.out',
                },
                0
              )
          })
        const scrollListTl = gsap.timeline({
          scrollTrigger: {
            trigger: '.scroll-list',
            scroller: '.scroll-section',
            start: 'top center',
            end: 'bottom center',
            scrub: true,
          },
          defaults: {
            ease: 'none',
          },
        })

        scrollListTl.fromTo(
          q('.line-container .line'),
          {
            yPercent: -100,
          },
          {
            yPercent: 0,
            ease: 'none',
          }
        )
        return function() {
          tl.kill()
          tl1.kill()
          tl2.kill()
          scrollVideoTl.kill()
          scrollListTl.kill()
        };
      },
      '(max-width : 1000px)': function () {
        gsap.set(
          [
            q('.video-container'),
            q('.scroll-container .film-title span span'),
            q('.scroll-container .film-title'),
          ],
          { clearProps: 'all' }
        )
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.scroll-container',
            scroller: '.scroll-section',
            start: 'top 75%',
            onEnter: () => setIsPlaying(true),
          },
        })

        // scroll list
        const tl1 = gsap.timeline().fromTo(
          q('.riprese-video-container .bg-img-subject'),
          {
            yPercent: 15,
          },
          {
            scrollTrigger: {
              trigger: '.riprese-video-container',
              scroller: '.scroll-section',
              start: 'top 75%',
              end: 'bottom top',
              scrub: true,
            },
            yPercent: 0,
          }
        )

        const tl2 = gsap.timeline().fromTo(
          q('.riprese-aeree-container .bg-img-bottom'),
          {
            yPercent: 0,
          },
          {
            scrollTrigger: {
              trigger: '.riprese-aeree-container',
              scroller: '.scroll-section',
              start: 'top 75%',
              end: 'bottom top',
              scrub: true,
            },
            yPercent: 4,
          }
        )


        const tl3 = gsap.timeline().fromTo(
          q('.editing-container .bg-img-subject.cursor'),
          {
            xPercent: 0,
          },
          {
            scrollTrigger: {
              trigger: '.editing-container',
              scroller: '.scroll-section',
              start: 'top 75%',
              end: 'bottom top',
              scrub: true,
            },
            xPercent: 100,
            ease: 'none',
          }
        )

        const tl4 = gsap.timeline().fromTo(
          q('.riprese-streaming-container .bg-img'),
          {
            yPercent: 0,
          },
          {
            scrollTrigger: {
              trigger: '.riprese-streaming-container',
              scroller: '.scroll-section',
              start: 'top 75%',
              end: 'bottom top',
              scrub: true,
            },
            yPercent: 4,
          }
        )
        
        gsap.utils
          .toArray('.scroll-list li:not(.line-container)')
          .forEach((li) => {
            const liTl = gsap.timeline({
              scrollTrigger: {
                trigger: li,
                scroller: '.scroll-section',
                start: 'top center',
                end: 'bottom center',
                toggleActions: 'play none none reverse',
                onEnter: () => liTl.timeScale(1.0),
                onEnterBack: () => liTl.timeScale(3.0),
                fastScrollEnd: true,
              },
            })
            liTl
              .fromTo(
                li.querySelector('.dot'),
                {
                  scale: 0,
                },
                {
                  scale: 1,
                  ease: 'back.out',
                },
                0
              )
              .fromTo(
                li.querySelector('span'),
                {
                  yPercent: 150,
                },
                {
                  yPercent: 0,
                  duration: 1.25,
                  ease: 'expo.out',
                },
                0
              )
          })
        const scrollListTl = gsap.timeline({
          scrollTrigger: {
            trigger: '.scroll-list',
            scroller: '.scroll-section',
            start: 'top center',
            end: 'bottom center',
            scrub: true,
          },
          defaults: {
            ease: 'none',
          },
        })

        scrollListTl.fromTo(
          q('.line-container .line'),
          {
            yPercent: -100,
          },
          {
            yPercent: 0,
            ease: 'none',
          }
        )

        return function() {
          tl.kill();
          tl1.kill()
          tl2.kill()
          tl3.kill()
          tl4.kill()
          scrollListTl.kill()
        };
      },
    })

    // audio section
    const img = document.querySelector('.index-audio-container .img-container')
    ScrollTrigger.matchMedia({
      '(min-width: 1101px)': () => {
        gsap.set(img, {
          yPercent: 0,
        })
        const imgTl = gsap.timeline({
          scrollTrigger: {
            trigger: img,
            scroller: '.scroll-section',
            start: 'top bottom',
            end: 'bottom top',
            scrub: 1,
          },
        })
        imgTl.fromTo(
          img,
          {
            yPercent: 10,
          },
          {
            yPercent: -10,
            ease: 'none',
          }
        )
      },
      '(max-width: 1100px)': () => {
        gsap.set(img, {
          yPercent: 0,
        })
      },
    })

    gsap.utils.toArray('.audio-player').forEach((player) => {
      ScrollTrigger.matchMedia({
        '(min-width: 1101px)': () => {
          gsap.set(player, {
            yPercent: 0,
          })
          const imgTl = gsap.timeline({
            scrollTrigger: {
              trigger: player,
              scroller: '.scroll-section',
              start: 'top bottom',
              end: 'bottom top',
              scrub: 1,
            },
          })
          imgTl.fromTo(
            player,
            {
              yPercent: 0,
            },
            {
              yPercent: -15,
              ease: 'none',
            }
          )
        },
        '(max-width: 1100px)': () => {
          gsap.set(player, {
            yPercent: 0,
          })
        },
      })
    })

    const ledTl = gsap.timeline({
      scrollTrigger: {
        trigger: '.audio-part-1',
        scroller: '.scroll-section',
        start: 'center 75%',
      },
    })

    ledTl.fromTo(
      q('.on-air'),
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
      }
    )

    gsap.utils.toArray('.foto-img-container').forEach((img) => {
      gsap.to(img, {
        y: 'random(-25,25)',
        x: 'random(-25,25)',
        ease: 'sine.inOut',
        repeatRefresh: true,
        duration: 2,
        repeat: -1,
        repeatDelay: 0,
        yoyo: true,
      })
    })

    // banner on scroll
    gsap.utils.toArray('.banner').forEach((banner, i) => {
      const spanWidth = banner.querySelector('span').offsetWidth
      const maxSpans = (window.screen.width + 100) / spanWidth + 1

      for (let i = 0; i < maxSpans; ++i) {
        const span = document.createElement('span')
        span.appendChild(
          document.createTextNode(banner.querySelector('span').textContent)
        )
        banner.appendChild(span)
      }

      const tl = gsap.timeline({
        paused: true,
        defaults: {
          ease: 'none',
          reversed: i % 2 ? true : false,
        },
        scrollTrigger: {
          trigger: banner,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 1,
          scroller: '.scroll-section',
        },
      })

      tl.fromTo(
        banner.querySelectorAll('span'),
        {
          xPercent: -100,
        },
        {
          xPercent: -5,
        }
      )
    })

    // // DON'T TOUCCHE GNIENTE DOPO QUA  // //

    // refresher for ScrollTrigger to work w/ LocomotiveScroll
    ScrollTrigger.addEventListener('refresh', () => scroll.current.update())
    ScrollTrigger.refresh()

    setTimeout(() => {
      scroll.current.update()
    }, 10)

    return () => {
      // stop observing
      observer1.disconnect()
      observer2.disconnect()
      // destroy Locomotive Scroll instace on page change
      scroll.current.destroy()
    }
  }, [])

  return (
    <>
      <Seo
        title=''
        description='Videomaker e Fotografo. Riprese video, riprese aeree, editing, registrazioni audio e shooting fotografici a Sinalunga, Siena.'
      />
      <div ref={index} className='index-container'>
        <section className='index-intro-container'>
          <div className='index-intro-text-container'>
            <h1 className='index-title'>
              <SplitText text='Giordano Betti ' />
              <span
                className='index-subtitle'
                sx={{ em: { color: 'primary' } }}
              >
                <em> Videomaker</em> <span>&</span> <em>Fotografo</em>
              </span>
            </h1>
            <span className='index-intro-separator'></span>
            <p className='index-desc'>
              <span>
                <strong>Riprese video</strong>, <strong>riprese aeree</strong>,{' '}
                <strong>live streaming</strong>, <strong>editing</strong>, <strong>registrazioni audio</strong>,{' '}
                <strong>mixing</strong> e <strong>shooting fotografici</strong>.
              </span>
            </p>
          </div>
          <StaticImage
            src='../images/pfp-1.jpg'
            alt='Foto Giordano Betti'
            className='img-intro-container img-intro-desktop shadow-black'
            imgClassName='img shadow-black'
            objectFit='cover'
          />
          <StaticImage
            src='../images/pfp-2.jpg'
            alt='Foto Giordano Betti'
            className='img-intro-container img-intro-mobile shadow-black'
            imgClassName='img shadow-black'
            objectFit='cover'
          />
          <ArrrowLink
            to='#film'
            text='Scopri di più'
            scroller={scroll.current}
            className='index-intro-more'
          />
        </section>
        <section id='film' className='index-film-container'>
          <h2 className='banner'>
            <span>Film</span>
          </h2>
          <div className='scroll-container'>
            <h3 className='film-title' sx={{ color: 'primary' }}>
              <span>
                <span>Dai&nbsp;</span>
              </span>
              <span>
                <span>vita&nbsp;</span>
              </span>
              <span>
                <span>alle&nbsp;</span>
              </span>
              <span>
                <span>tue&nbsp;</span>
              </span>
              <span>
                <span>idee</span>
              </span>
            </h3>
            <div className='video-container'>
              <ReactPlayer
                url={videoSrc}
                muted={true}
                playing={isPlaying}
                loop={true}
                width='100%'
                height='min-content'
                className='film-main-video shadow-black'
                ref={videoRef}
              />
            </div>
          </div>

          <div className='film-container'>
            <p className='title-p-em'>
              <span sx={{ bg: 'primary' }}></span>
              <span sx={{ color: 'primary' }}>
                e racconta la tua storia nel migliore dei modi
              </span>
            </p>

            <p className='text-presentation'>
              <span>
                Sono un{' '}
                <strong>
                  <em>videomaker</em> di Sinalunga
                </strong>{' '}
                e registro video per pubblicità, prodotti, servizi ed aziende o
                eventi, realizzo videoclip e molto altro. Il mio{' '}
                <em>obiettivo</em> è portare alla realtà la tua immaginazione e
                poter realizzare insieme i <em>tuoi progetti</em>.
              </span>
            </p>

            <div className='riprese-video-container'>
              <StaticImage
                src='../images/riprese-video-no-camera.jpg'
                alt='Riprese video senza camera per effetto parallax'
                className='bg-img'
                objectFit='auto'
                placeholder='blurred'
                quality={100}
              />
              <StaticImage
                src='../images/riprese-video-camera.png'
                alt='Foto camera per effetto parallax'
                className='bg-img-subject'
                objectFit='auto'
                placeholder='blurred'
                draggable={false}
                quality={100}
              />
              <p className='img-title' sx={{ color: 'primary' }}>
                <span>Riprese Video</span>
              </p>
            </div>

            <div className='riprese-aeree-container'>
              <StaticImage
                src='../images/riprese-aeree-top.png'
                alt='Riprese aeree top scena per effetto parallax'
                className='bg-img'
                objectFit='auto'
                placeholder='blurred'
                quality={100}
              />
              <StaticImage
                src='../images/riprese-aeree-bottom.png'
                alt='Riprese aeree bottom scena per effetto parallax'
                className='bg-img-bottom'
                objectFit='auto'
                placeholder='blurred'
                quality={100}
              />
              <p className='img-title' sx={{ color: 'primary' }}>
                <span>Riprese Aeree</span>
              </p>
            </div>

            <div className='editing-text-container'>
              <div className='editing-container'>
                <StaticImage
                  src='../images/editing-bg.jpg'
                  alt='Editing senza timeline per effetto parallax'
                  className='bg-img'
                  objectFit='auto'
                  placeholder='blurred'
                  quality={100}
                />
                <StaticImage
                  src='../images/editing-top-timeline.png'
                  alt='Top timeline per effetto parallax'
                  className='bg-img-subject top'
                  objectFit='auto'
                  placeholder='blurred'
                  draggable={false}
                  quality={100}
                />
                <StaticImage
                  src='../images/editing-bottom-timeline.png'
                  alt='Bottom timeline per effetto parallax'
                  className='bg-img-subject bottom'
                  objectFit='auto'
                  placeholder='blurred'
                  draggable={false}
                  quality={100}
                />
                <StaticImage
                  src='../images/editing-cursor.png'
                  alt='Cursore timeline per effetto parallax'
                  className='bg-img-subject cursor'
                  objectFit='auto'
                  placeholder='blurred'
                  draggable={false}
                  quality={100}
                />
                <p className='img-title' sx={{ color: 'primary' }}>
                  <span>Editing</span>
                </p>
              </div>

              <div className='film-text'>
                <p>
                  <span>
                    Non si tratta solamente di creare un video, ma di{' '}
                    <em>trasmettere </em> quello che hai in mente.
                  </span>
                </p>
                <p>
                  <span>
                    La <em>realizzazione</em> di un video richiede un percorso
                    in cui nulla è scontato ed essere seguiti da un{' '}
                    <em>professionista</em> step by step è la chiave vincente
                    per portare la tua storia dove merita.
                  </span>
                </p>
              </div>
            </div>

            <div className='riprese-streaming-container'>
              <StaticImage
                src='../images/riprese-streaming-bottom.jpg'
                alt='Riprese streaming top scena per effetto parallax'
                className='bg-img'
                objectFit='auto'
                placeholder='blurred'
                quality={100}
              />
              <StaticImage
                src='../images/riprese-streaming-top.png'
                alt='Riprese streaming bottom scena per effetto parallax'
                className='bg-img-bottom'
                objectFit='auto'
                placeholder='blurred'
                quality={100}
              />
              <p className='img-title' sx={{ color: 'primary' }}>
                <span>Live Streaming</span>
              </p>
            </div>

            <h4 className='scroll-list-title'>
              <span>Quali step?</span>
            </h4>

            <div style={{ flexBasis: '100%' }}>
              <ul className='scroll-list'>
                <li className='line-container'>
                  <div className='line' sx={{ bg: 'secondary' }}></div>
                </li>
                <li>
                  <div className='dot' sx={{ bg: 'secondary' }}></div>
                  <span sx={{ color: 'primary' }}>Ideazione</span>
                </li>
                <li>
                  <div className='dot' sx={{ bg: 'secondary' }}></div>
                  <span sx={{ color: 'primary' }}>Pre-produzione</span>
                </li>
                <li>
                  <div className='dot' sx={{ bg: 'secondary' }}></div>
                  <span sx={{ color: 'primary' }}>Shooting</span>
                </li>
                <li>
                  <div className='dot' sx={{ bg: 'secondary' }}></div>
                  <span sx={{ color: 'primary' }}>Editing</span>
                </li>
                <li>
                  <div className='dot' sx={{ bg: 'secondary' }}></div>
                  <span sx={{ color: 'primary' }}>Enjoy</span>
                </li>
              </ul>
            </div>

            <div className='film-engage'>
              <p>
                <strong>Il video è il mezzo più potente</strong> per raccontare
                una storia o vendere un prodotto, se fatto con cura.
              </p>
              <p className='engage-main'>
                Tanti hanno già iniziato,{' '}
                <strong sx={{ color: 'primary' }}>tu cosa aspetti?</strong>
              </p>
              <div className='engage-buttons'>
                <TransButton
                  to='/portfolio'
                  type='decorative'
                  className='engage-button-1'
                >
                  Scopri i miei lavori
                </TransButton>
                <TransButton
                  to='/contatti'
                  type='decorative'
                  className='engage-button-2'
                >
                  Mettiamoci in contatto
                </TransButton>
              </div>
            </div>
          </div>
        </section>
        <section
          id='audio'
          className='index-audio-container'
          sx={{ '.audio-player': { bg: 'secondary' } }}
        >
          <h2 className='banner'>
            <span>Audio</span>
          </h2>
          <p className='text-em'>
            L’<em>audio</em> fa parte di un video più delle immagini stesse e
            ciò determina se verrà visto <em>fino alla fine</em> o meno.
          </p>
          <StaticImage
            src='../images/audio.jpg'
            alt='Setting audio'
            className='img-container audio-img-container shadow-black'
          />
          <div className='audio-part-1'>
            <p className='on-air' sx={{ color: 'primary' }}>
              On Air
            </p>
            <p>
              Completare un lavoro significa{' '}
              <em>coprire a 360° ogni aspetto del progetto</em>.
            </p>
            <p>
              Dedicarsi in maniera particolare e curata all’audio non è un plus
              ma un requisito obbligatorio per un <em>risultato di qualità</em>.
            </p>
            <p>
              Non si chiude un’occhio e non si lascia nulla al caso, la
              professionalità si vede anche nei <em>dettagli</em>.
            </p>
          </div>
          <div className='audio-part-2'>
            <h3>
              <SplitText text='Registra la Tua musica' />
            </h3>
            <p>
              Registra in modo professionale e dai la possibilità al mondo di
              ascoltarti.
            </p>
            <p>
              <em>Album</em>, <em>concerti</em>, <em>audizioni</em> o{' '}
              <em>CD</em>
            </p>
          </div>
          <AudioPlayer tracks={track1} />
          <h4 className='audio-slogan' sx={{ color: 'primary' }}>
            <em>non lasciare la tua musica in silenzio.</em>
          </h4>
          <AudioPlayer tracks={track2} className='audio-player-plus' />
          <TransButton
            to='/contatti'
            title='Per la pagina contatti'
            type='decorative'
            className='audio-contatti'
          >
            Iniziamo una nuova registrazione
          </TransButton>
        </section>
        <section id='foto' className='index-foto-container'>
          <h2 className='banner'>
            <span>Foto</span>
          </h2>
          <div className='foto-imgs-container'>
            <StaticImage
              src='../images/photo/palazzo-vecchio-photo-1.jpg'
              alt='Alessia Bettini vicesindaco Firenze'
              className='foto-img-container foto-img-vertical-container shadow-black'
            />
            <StaticImage
              src='../images/photo/palazzo-vecchio-photo-6.jpg'
              alt='Alessia Bettini vicesindaco Firenze'
              className='foto-img-container shadow-black'
            />
            <StaticImage
              src='../images/photo/palazzo-vecchio-photo-2.jpg'
              alt='Alessia Bettini vicesindaco Firenze'
              className='foto-img-container foto-img-vertical-container shadow-black'
            />
            <StaticImage
              src='../images/photo/museo-medici-photo-2.jpg'
              alt='Bottega del Foggini'
              className='foto-img-container'
            />
            <StaticImage
              src='../images/photo/palazzo-vecchio-photo-5.jpg'
              alt='Alessia Bettini vicesindaco Firenze'
              className='foto-img-container foto-img-vertical-container shadow-black'
            />
            <StaticImage
              src='../images/photo/palazzo-vecchio-photo-3.jpg'
              alt='Alessia Bettini vicesindaco Firenze'
              className='foto-img-container foto-img-vertical-container shadow-black'
            />
            <StaticImage
              src='../images/photo/palazzo-vecchio-photo-4.jpg'
              alt='Alessia Bettini vicesindaco Firenze'
              className='foto-img-container shadow-black'
            />
          </div>
        </section>
      </div>
    </>
  )
}

export default Index
